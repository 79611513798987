import {formatPrettyString} from "src/utils/formatting.js";

export const RegressionModel = {
    LINEAR: "linear",
    DECISION_TREE: "decision_tree",
    RANDOM_FOREST: "random_forest",
    MULTI_LAYER_PERCEPTRON: "multi_layer_perceptron"
};

export const modelOptions = Object.keys(RegressionModel).map((key) => ({
    key,
    value: RegressionModel[key],
    name: formatPrettyString(key)
}));


export const Transformations = {
    PASSTHROUGH: "passthrough",
    STANDARD: "standard",
    LOG_NORMAL: "log_normal",
    MIN_MAX: "min_max",
    ONE_HOT: "one_hot"
};

export const transformationOptions = Object.keys(Transformations).map((key) => ({
    key,
    value: Transformations[key],
    name: formatPrettyString(key)
}));
