import {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {GET_PREDICTION_MODEL_QUERY} from "./useGetPredictionModel.js";
import {MODEL_RESULT_FIELDS} from "./fragments.js";


const MUTATION = gql`
    ${MODEL_RESULT_FIELDS}
    mutation TrainModel($modelId: String!, $settings: TrainModelInput!) {
        trainModel(modelId: $modelId, settings: $settings) {
            ok
            errorCode
            result {
                ... ModelResultFields
            }
        }
    }
`;


export function useTrainModel(modelId) {
    const [mutation, {loading, error, data}] = useMutation(MUTATION);

    const doMutate = useCallback((settings) => {
        const refetchQueries = [{query: GET_PREDICTION_MODEL_QUERY, variables: {modelId}}];
        return mutation({
            variables: {modelId, settings},
            refetchQueries
        }).then((response) => {
            return response.data.trainModel;
        });
    }, [mutation, modelId]);

    return [doMutate, {loading, error, result: data?.trainModel?.result}];
}
