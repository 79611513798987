import React, {useState} from "react";
import PropTypes from "prop-types";
import {Navigate} from "react-router-dom";
import {useGetLink} from "src/utils/useGetLink.js";
import {GoBackLink} from "src/primitives/navigation/GoBackLink.jsx";
import {LINKS} from "src/links.js";
import {Button} from "src/primitives/button.jsx";
import {useDeletePredictionModel} from "src/api/predictionModels/useDeletePredictionModel.js";


export function PredictionModelHeader({tableId, modelId}) {
    const getLink = useGetLink();
    const [doDelete, {loading}] = useDeletePredictionModel(modelId);
    const [isDeleted, setIsDeleted] = useState(false);
    const handleDelete = () => {
        doDelete().then((response) => {
            if (response.ok) {
                setIsDeleted(true);
            } else {
                setIsDeleted(false);
            }
        });
    };

    if (isDeleted) {
        return <Navigate to={getLink(LINKS.tablePredict(tableId))}/>;
    }

    return (
        <div className="w-full flex-shrink-0 h-10 relative border-b border-neutral-300 flex">
            <div className="flex-grow flex items-center justify-center">
                <div className="text-xs monospace text-neutral-600 pr-4">
                    {modelId}
                </div>
            </div>
            <div className="absolute left-0 top-0">
                <div className="ml-2 mt-1">
                    <GoBackLink link={getLink(LINKS.tablePredict(tableId))} title="Back"/>
                </div>
            </div>
            <div className="absolute right-0 top-0">
                <div className="mt-1.5 mr-4">
                    <Button
                        onClick={handleDelete}
                        isLoading={loading}
                        theme="danger"
                        size="xs"
                    >
                        Delete
                    </Button>
                </div>
            </div>
        </div>
    );
}

PredictionModelHeader.propTypes = {
    tableId: PropTypes.string.isRequired,
    modelId: PropTypes.string.isRequired
};
