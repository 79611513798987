import React from "react";
import PropTypes from "prop-types";
import {ScatterPlot} from "src/charts/ScatterPlot.jsx";
import {Title} from "src/primitives/title.jsx";
import {Histogram} from "src/charts/index.js";


export function ModelResiduals({residuals}) {
    const height = 300;
    const relativeResidualValues = residuals.map((r) => r.relative_residual);

    return (
        <div className="flex flex-col gap-4">
            <div className="max-w-144">
                <Title size="small">
                    Residual values
                </Title>
                <ScatterPlot
                    height={height}
                    data={residuals}
                    xName="y_true"
                    yName="y_pred"
                    // xMin={Math.floor(xStats?.p1)}
                    // xMax={Math.ceil(xStats?.p99)}
                    // yMin={Math.floor(yStats?.p1)}
                    // yMax={Math.ceil(yStats?.p99)}
                />
            </div>
            <div className="max-w-144">
                <Title size="small">
                    Relative residuals vs. true values
                </Title>
                <ScatterPlot
                    height={height}
                    data={residuals}
                    xName="y_true"
                    yName="relative_residual"
                    // xMin={Math.floor(xStats?.p1)}
                    // xMax={Math.ceil(xStats?.p99)}
                    // yMin={Math.floor(yStats?.p1)}
                    // yMax={Math.ceil(yStats?.p99)}
                />
            </div>
            <div className="max-w-144">
                <Title size="small">
                    Relative residuals distribution
                </Title>
                <Histogram
                    height={height}
                    values={relativeResidualValues}
                />
            </div>
        </div>
    );
}

ModelResiduals.propTypes = {
    residuals: PropTypes.arrayOf(PropTypes.object)
};
