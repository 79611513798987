import React from "react";
import PropTypes from "prop-types";
import {ModelResiduals} from "./components/ModelResiduals/index.jsx";


export function ModelResult({model}) {
    const residuals = model?.residuals;
    return (
        <div>
            {residuals && (
                <ModelResiduals residuals={residuals}/>
            )}
        </div>
    );
}
ModelResult.propTypes = {
    model: PropTypes.shape({
        residuals: PropTypes.arrayOf(PropTypes.object)
    })
};
