import React from "react";
import PropTypes from "prop-types";
import {useGetPredictionModels} from "src/api/predictionModels/useGetPredictionModels.js";
import {useCreatePredictionModel} from "src/api/predictionModels/useCreatePredictionModel.js";
import {Button} from "src/primitives/button.jsx";
import {Link} from "react-router-dom";
import {useGetLink} from "src/utils/useGetLink.js";
import {LINKS} from "src/links.js";
import {Spinner} from "src/primitives/spinner.jsx";
import {formatDate} from "src/utils/formatting.js";
import {sortByDate} from "src/utils/sorting.js";


export function PredictionModels({tableId}) {
    const getLink = useGetLink();
    const [doCreate, {loading: creating}] = useCreatePredictionModel(tableId);
    const {models, loading} = useGetPredictionModels(tableId);

    if (loading) {
        return (
            <div className="full flex justify-center items-center">
                <Spinner/>
            </div>
        );
    }

    const modelsSorted = sortByDate(models, "createdAt", "desc");

    return (
        <div>
            <div className="mb-4">
                <Button onClick={() => doCreate()} isLoading={creating}>
                    Create Prediction Model
                </Button>
            </div>
            <div className="flex flex-col gap-2">
                {modelsSorted.map((item) => {
                    return (
                        <Link
                            to={getLink(LINKS.tablePredictionModel(tableId, item.id))}
                            key={item.id}
                            className="block mb-4 p-4 rounded-md border border-neutral-500 w-96 text-sm"
                        >
                            <div>
                                ID: {item.id}
                            </div>
                            <div>
                                Created: {formatDate(item.createdAt)}
                            </div>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
}
PredictionModels.propTypes = {
    tableId: PropTypes.string.isRequired
};
