import React from "react";
import PropTypes from "prop-types";
import {Accordion} from "src/primitives/Accordion/index.jsx";
import {Badge} from "src/primitives/badge.jsx";
import {SelectInput} from "src/primitives/controls/index.jsx";
import {transformationOptions} from "src/pages/Home/pages/TablePage/pages/TablePredictPage/constants.js";
import {SmoothLineChart} from "src/charts/SmoothLineChart.jsx";
import {
    formatDistributionToLine,
    maxValueFromDistribution,
    minValueFromDistribution
} from "src/components/TableGrid/Header/ColumnFilter/components/utils.js";
import {CHART_COLORS} from "src/charts/colors.js";
import {useGetColumnDistributions} from "src/api/tableData/useGetColumnDistributions.js";
import {useCurrentTableId} from "src/pages/Home/contexts/current_table_context.jsx";


export function ModelTarget({targetColumn, targetTransformation, setTargetTransformation}) {
    const tableId = useCurrentTableId();
    const {data} = useGetColumnDistributions(tableId, [targetColumn?.name]);
    const distribution = data && targetColumn?.name ? data[targetColumn.name] : null;
    const xMin = minValueFromDistribution(distribution);
    const xMax = maxValueFromDistribution(distribution);
    const chartOptions = {
        showXLabel: true,
        showYLabel: false,
        xMin,
        xMax,
        colors: [CHART_COLORS.lime]
    };

    return (
        <Accordion
            size="xs"
            theme="light"
            defaultState={true}
            title={(
                <div className="flex items-center gap-4">
                    {targetColumn ? (
                        <>
                            <Badge theme="blue">
                                {targetColumn.name}
                            </Badge>
                            <Badge theme="orange">
                                {targetColumn.variableType}
                            </Badge>
                        </>
                    ) : (
                        <span>
                            No target column selected
                        </span>
                    )}
                </div>
            )}
        >
            <div>
                <div className="max-w-80">
                    <SelectInput
                        label="Target transformation"
                        options={transformationOptions}
                        onChange={(option) => setTargetTransformation(option?.value)}
                        selectedOption={transformationOptions.find((item) => item.value === targetTransformation)}
                    />
                </div>
                {distribution && (
                    <div
                        className="mt-8 mb-4 ml-[-20px] w-128"
                    >
                        <SmoothLineChart
                            height={100}
                            options={chartOptions}
                            data={formatDistributionToLine(distribution)}
                        />
                    </div>
                )}
            </div>
        </Accordion>
    );
}

ModelTarget.propTypes = {
    targetColumn: PropTypes.shape({
        name: PropTypes.string.isRequired,
        variableType: PropTypes.string.isRequired
    }),
    targetTransformation: PropTypes.string,
    setTargetTransformation: PropTypes.func
};
