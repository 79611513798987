import React, {useState} from "react";
import PropTypes from "prop-types";
import {useTrainModel} from "src/api/predictionModels/useTrainModel.js";
import {modelOptions, RegressionModel} from "src/pages/Home/pages/TablePage/pages/TablePredictPage/constants.js";
import {SelectInput} from "src/primitives/controls/index.jsx";
import {Button, IconButton} from "src/primitives/button.jsx";
import {Title} from "src/primitives/title.jsx";
import {sortFeaturesByVariables} from "src/pages/Home/pages/TablePage/pages/TablePredictPage/utils.js";
import {FiPlus, FiTarget} from "react-icons/fi";
import {SelectColumn} from "src/components/controls/SelectColumn/index.jsx";
import {ModelTarget} from "./components/ModelTarget/index.jsx";
import {ModelResult} from "./components/ModelResult/index.jsx";
import {ModelFeatures} from "./components/ModelFeatures/index.jsx";


export function TrainModel({tableId, modelId, defaultTarget, defaultFeatures, tableColumns}) {
    const [selectedModel, setSelectedModel] = useState(RegressionModel.LINEAR);
    const [selectedTarget, setSelectedTarget] = useState(defaultTarget);
    const [targetTransformation, setTargetTransformation] = useState(null);
    const [selectedFeatures, setSelectedFeatures] = useState(defaultFeatures);
    const [doTrain, {loading, result}] = useTrainModel(modelId);

    const handleTrainModel = () => {
        if (!selectedTarget || !selectedModel || !selectedFeatures) {
            return;
        }
        const settings = {
            model: selectedModel,
            targetColumn: selectedTarget.name,
            targetTransformation
        };

        doTrain(settings);
    };

    const handleTransformationChange = (columnName, transformation) => {
        setSelectedFeatures((prev) => {
            return prev.map((item) => {
                if (item.name === columnName) {
                    return {
                        ...item,
                        transformation: transformation?.value
                    };
                }
                return item;
            });
        });
    };

    const isColumnSelectedFeature = (columnName) => {
        return selectedFeatures.find((item) => item.name === columnName);
    };

    const handleSelectTarget = (column) => {
        setSelectedTarget(column);
    };

    const handleAddNewColumn = (column) => {
        if (isColumnSelectedFeature(column.name)) {
            // remove
            setSelectedFeatures((prev) => {
                return prev.filter((item) => item.name !== column.name);
            });
        } else {
            // add
            setSelectedFeatures((prev) => {
                return [...prev, column];
            });
        }
    };

    if (result) {
        console.log(setSelectedTarget);
    }

    const sortedFeatures = sortFeaturesByVariables(selectedFeatures, tableColumns);

    return (
        <div>
            <div className="mb-6 max-w-160">
                <Title size="small">
                    Select regression model
                </Title>
                <div className="max-w-80">
                    <SelectInput
                        onChange={(option) => setSelectedModel(option?.value)}
                        options={modelOptions}
                        selectedOption={modelOptions.find((item) => item.value === selectedModel)}
                    />
                </div>
            </div>

            <div className="mb-6 max-w-160 border-b border-black/10">
                <div className="flex items-center justify-between mb-2">
                    <Title size="small">
                        Target column
                    </Title>
                    <div className="text-sm">
                        <SelectColumn
                            tableId={tableId}
                            onSelect={handleSelectTarget}
                            selected={selectedTarget?.name}
                        >
                            <IconButton
                                icon={<FiTarget/>}
                                text="Select target"
                            />
                        </SelectColumn>
                    </div>
                </div>
                <ModelTarget
                    targetColumn={selectedTarget}
                    targetTransformation={targetTransformation}
                    setTargetTransformation={setTargetTransformation}
                />
            </div>

            <div className="mb-6 max-w-160 border-b border-black/10">
                <div className="flex items-center justify-between mb-2">
                    <Title size="small">
                        Features
                    </Title>
                    <div className="text-sm">
                        <SelectColumn
                            tableId={tableId}
                            onSelect={handleAddNewColumn}
                            isSelected={isColumnSelectedFeature}
                        >
                            <IconButton
                                icon={<FiPlus/>}
                                text="Select features"
                            />
                        </SelectColumn>
                    </div>
                </div>
                <ModelFeatures
                    tableId={tableId}
                    features={sortedFeatures}
                    target={selectedTarget}
                    setTransformation={handleTransformationChange}
                />
            </div>
            <div className="mb-4">
                <div className="flex flex-col gap-4 max-w-80">
                    <div />
                    <div>
                        <Button
                            onClick={handleTrainModel}
                            isLoading={loading}
                            isDisabled={!selectedTarget || !selectedModel}
                        >
                            Train model
                        </Button>
                    </div>
                </div>
            </div>

            {result && (
                <div className="mb-4">
                    <Title size="small">
                        Result:
                    </Title>
                    <ModelResult result={result}/>
                </div>
            )}
        </div>
    );
}

TrainModel.propTypes = {
    tableId: PropTypes.string.isRequired,
    modelId: PropTypes.string.isRequired,
    defaultTarget: PropTypes.object,
    defaultFeatures: PropTypes.arrayOf(PropTypes.object),
    tableColumns: PropTypes.arrayOf(PropTypes.object)
};
