import {gql} from "@apollo/client";


export const MODEL_RESULT_FIELDS = gql`
  fragment ModelResultFields on ModelResult {
    r2
    mse
    mae
    mape
  }
`;

export const PREDICTION_MODEL_FIELDS = gql`
  fragment PredictionModelFields on PredictionModel {
    id
    createdAt
    title
  }
`;
