import React from "react";
import PropTypes from "prop-types";
import {useGetTable} from "src/api/tables/useGetTable.js";
import {useGetPredictionModel} from "src/api/predictionModels/useGetPredictionModel.js";
import {TrainModel} from "./components/TrainModel/index.jsx";
import {PredictionModelHeader} from "./components/PredictionModelHeader/index.jsx";
import {ModelPreview} from "./components/ModelPreview/index.jsx";
import {ModelResult} from "./components/ModelResult/index.jsx";


export function PredictionModelPage({tableId, modelId}) {
    const {model, loading: loadingModel} = useGetPredictionModel(modelId);
    const {table, loading: loadingTable} = useGetTable(tableId);
    const columns = table?.columns || [];

    const target = columns.find((item) => item.isTarget);
    const features = columns.filter((item) => item.isFeature);

    if (loadingTable || loadingModel) {
        return null;
    }

    console.log("Model settings: ", model?.settings);

    return (
        <div className="full flex flex-col overflow-hidden">
            <PredictionModelHeader tableId={tableId} modelId={modelId}/>
            <div className="flex-grow overflow-auto p-4">
                <TrainModel
                    tableId={tableId}
                    modelId={modelId}
                    defaultTarget={target}
                    defaultFeatures={features}
                    tableColumns={columns}
                />

                <div className="my-6">
                    <hr/>
                    <div className="my-4">
                        <ModelPreview tableId={tableId} modelId={modelId}/>
                    </div>
                </div>
                <div className="my-6">
                    <ModelResult model={model}/>
                </div>
            </div>
        </div>
    );
}

PredictionModelPage.propTypes = {
    tableId: PropTypes.string.isRequired,
    modelId: PropTypes.string.isRequired
};
