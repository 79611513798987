import React from "react";
import PropTypes from "prop-types";
import {useGetValues} from "src/api/tableData/useGetValues.js";
import {Spinner} from "src/primitives/spinner.jsx";
import {Accordion} from "src/primitives/Accordion/index.jsx";
import {Badge} from "src/primitives/badge.jsx";
import {SelectInput} from "src/primitives/controls/index.jsx";
import {transformationOptions} from "src/pages/Home/pages/TablePage/pages/TablePredictPage/constants.js";
import {FeatureVisualization} from "./components/FeeatureVisualization/index.jsx";


export function ModelFeatures({tableId, target, features, setTransformation}) {
    const columnNames = features.map(({name}) => name);
    if (target) {
        columnNames.push(target.name);
    }
    const {data: values, loading: loadingValues} = useGetValues(tableId, columnNames);

    if (loadingValues) {
        return (
            <Spinner/>
        );
    }

    return (
        <div className="flex flex-col">
            {features.map((column) => (
                <div
                    key={column.name}
                >
                    <Accordion
                        size="xs"
                        theme="light"
                        defaultState={true}
                        title={(
                            <div className="flex items-center gap-4">
                                <Badge theme="blue">
                                    {column.name}
                                </Badge>
                                <Badge theme="orange">
                                    {column.variableType}
                                </Badge>
                            </div>
                        )}
                    >
                        <div className="min-h-72 w-full">
                            <div className="mb-6 max-w-80">
                                <SelectInput
                                    label="Select transformation"
                                    options={transformationOptions}
                                    onChange={setTransformation.bind(null, column.name)}
                                    /* eslint-disable-next-line max-len */
                                    selectedOption={transformationOptions.find((item) => item.value === column.transformation)}
                                />
                            </div>

                            {target && (
                                <div>
                                    <FeatureVisualization
                                        tableId={tableId}
                                        featureColumn={column}
                                        targetColumn={target}
                                        values={values}
                                    />
                                </div>
                            )}
                        </div>
                    </Accordion>
                </div>
            ))}
        </div>
    );
}


ModelFeatures.propTypes = {
    tableId: PropTypes.string.isRequired,
    features: PropTypes.arrayOf(PropTypes.object),
    target: PropTypes.object,
    setTransformation: PropTypes.func
};
